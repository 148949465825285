import React from 'react';
import styled from 'styled-components';
import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import Title from 'stoomlink-commons-ui/Title/Title';
import NewsletterForm from '../components/NewsletterForm';
import Layout from '../layouts/Layout';
import Head from '../layouts/Head';
import Container from '../layouts/Container';
import Footer from '../components/Footer';
import HeroImg from '../assets/images/newsletter-hero.jpg';

const TitleContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 24px;

  @media screen and (min-width: 780px){
    padding-top: 160px;
    padding-bottom: 48px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 780px){
    display: flex;
    flex-wrap: nowrap;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  order: 2;

  @media screen and (min-width: 780px) {
    order: unset;
    width: calc(100% / 20 * 9);
  }
`;
const RightSide = styled.div`
  order: 1;
  width: 100%;

  @media screen and (min-width: 780px) {
    order: unset;
    margin-left: calc(100% / 20 * 2);
    width: calc(100% / 20 * 9);
  }
`;
const Hero = styled.div`
  margin-top: 32px;
  width: 100%;
  padding-bottom: 56.25%;
  background-image: url('${HeroImg}');
  background-position: center 85%;
  background-size: cover;

  @media screen and (min-width: 780px) {
    margin-top: 80px;
    padding-bottom: 149.8%;
    background-position: center;
  }
`;

const NewsletterPage = () => {

  return (
    <ThemeProvider themeStyle="light">
      <Layout>
        <Head title="Newsletter" />
        <Container>
          <ContentContainer>
            <LeftSide>
              <TitleContainer>
                <Title displayLevel={5}>
                  Join our monthly newsletter.
                </Title>
              </TitleContainer>
              <NewsletterForm showTitle={false} isInline />
            </LeftSide>
            <RightSide>
              <Hero />
            </RightSide>
          </ContentContainer>
        </Container>
        <Footer className="noBefore" />
      </Layout>
    </ThemeProvider>
  );
};

export default NewsletterPage;
